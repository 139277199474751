<template>
  <div id="pricing" class="mt-32">
    <div class="bg-gray-700">
      <div class="px-4 pt-12 sm:px-6 lg:px-8 lg:pt-20">
        <div class="text-center">
          <p class="mt-2 text-3xl font-bold tracking-tight text-white sm:text-3xl lg:text-5xl">Start testing like a pro</p>
          <p class="mx-auto mt-3 max-w-4xl text-xl text-gray-300 sm:mt-5 sm:text-2xl">Buy once. Forever yours.</p>
        </div>
      </div>

      <div class="mt-16 bg-white pb-12 lg:mt-20 lg:pb-20">
        <div class="relative z-0">
          <div class="absolute inset-0 h-5/6 bg-gray-700 lg:h-2/3"></div>
          <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div class="relative lg:grid lg:grid-cols-7">
              <!-- Basic package -->
              <div class="mx-auto max-w-md lg:col-span-3 lg:row-start-2 lg:row-end-3 lg:mx-0 lg:max-w-none">
                <div class="flex h-full flex-col overflow-hidden rounded-lg shadow-lg lg:rounded-none lg:rounded-l-lg">
                  <div class="flex flex-1 flex-col">
                    <div class="bg-white px-6 py-10">
                      <div>
                        <h3 class="text-center text-2xl font-medium text-gray-900" id="tier-hobby">
                          Basic Package
                        </h3>
                        <div class="mt-4 flex flex-col items-center justify-center">
                          <span class="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                            <span class="mt-2 mr-2 text-4xl font-medium tracking-tight">{{ currency }}</span>
                            <span class="font-bold">{{ basicPrice }}</span>
                          </span>
                        </div>
                        <div v-if="basicPriceTax">
                          + sales tax {{ currency }} {{ basicPriceTax }}
                        </div>
                        <p class="text-lg pt-10">
                          Understand the basics for testing serverless architectures, and how to test
                          simple applications such as REST APIs and GraphQL APIs.
                        </p>
                      </div>
                    </div>
                    <div class="flex flex-1 flex-col justify-between border-t-2 border-gray-100 bg-gray-50 p-6 sm:p-10 lg:p-6 xl:p-10">
                      <ul role="list" class="space-y-4">
                        <PricingFeature :name="`General strategy for testing serverless architectures`"/>
                        <PricingFeature :name="`How to test API Gateway APIs`"/>
                        <PricingFeature :name="`How to test AppSync APIs`"/>
                        <PricingFeature :name="`Access to source code from the above chapters`"/>
                      </ul>
                      <div class="mt-8">
                        <div class="rounded-lg shadow-md mb-2">
                          <a href="#"
                             class="checkout-button block w-full rounded-lg border border-transparent bg-white px-6 py-3 text-center text-base font-medium text-indigo-600 hover:bg-gray-50" aria-describedby="tier-hobby"
                             @click.prevent="buyBasic">
                            Understand testing
                          </a>
                        </div>

                        <a class="link"
                           target="_blank"
                           href="https://theburningmonk.thinkific.com/courses/testing-serverless-architectures-basic">
                          Free preview available
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Pro package -->
              <div class="mx-auto mt-10 max-w-lg lg:col-span-4 lg:row-start-1 lg:row-end-4 lg:mx-0 lg:mt-0 lg:max-w-none">
                <div class="relative z-10 rounded-lg shadow-xl">
                  <div class="pointer-events-none absolute inset-0 rounded-lg border-2 border-indigo-600" aria-hidden="true"></div>
                  <div class="absolute inset-x-0 top-0 translate-y-px transform">
                    <div class="flex -translate-y-1/2 transform justify-center">
                      <span class="inline-flex rounded-full bg-indigo-600 px-4 py-1 text-base font-semibold text-white">
                        MOST POPULAR
                      </span>
                    </div>
                  </div>
                  <div class="rounded-t-lg bg-white px-6 pt-12 pb-10">
                    <div>
                      <h3 class="text-center text-3xl font-semibold tracking-tight text-gray-900 sm:-mx-6" id="tier-growth">
                        Pro Package
                      </h3>
                      <div class="mt-4 flex flex-col items-center justify-center">
                        <span class="flex items-start px-3 text-6xl tracking-tight text-gray-900">
                          <span class="mt-2 mr-2 text-4xl font-medium tracking-tight">{{ currency }}</span>
                          <span class="font-bold">{{ proPrice }}</span>
                        </span>
                      </div>
                      <div v-if="proPriceTax">
                        + sales tax {{ currency }} {{ proPriceTax }}
                      </div>
                      <p class="text-lg pt-10">
                        Learn how to test even complex serverless architectures such as those involving
                        Step Functions and event-driven architectures.
                      </p>
                    </div>
                  </div>
                  <div class="rounded-b-lg border-t-2 border-gray-100 bg-gray-50 px-6 pt-10 pb-8 sm:px-10 sm:py-10">
                    <ul role="list" class="space-y-4">
                      <PricingFeature :name="`Everything in the basic package`"/>
                      <PricingFeature :name="`How to test Step Functions`"/>
                      <PricingFeature :name="`How to test event-driven architectures (EDAs)`"/>
                      <PricingFeature :name="`Testing in production`"/>
                      <PricingFeature :name="`Access to source code from all six chapters in the course`"/>
                    </ul>
                    <div class="mt-10">
                      <div class="rounded-lg shadow-md mb-2">
                        <a href="#"
                           class="checkout-button block w-full rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-center text-xl font-medium leading-6 text-white hover:bg-indigo-700" aria-describedby="tier-growth"
                           @click.prevent="buyPro">
                          Test like a pro
                        </a>
                      </div>
                      <p class="mt-2 mb-4">
                        <a class="link"
                          target="_blank"
                          href="https://theburningmonk.thinkific.com/courses/testing-serverless-architectures-pro">
                          Free preview available
                        </a>
                      </p>
                      <p>
                        <a class="link"
                          target="_blank"
                          href="#"
                          @click.prevent="showTeamLicenses = !showTeamLicenses">
                          <span v-if="!showTeamLicenses">Show team licenses</span>
                          <span v-if="showTeamLicenses">Hide team licenses</span>
                        </a>
                      </p>
                      <div v-if="showTeamLicenses">
                        <ul class="mt-2 flex flex-col">
                          <li class="w-64 mx-auto flex flex-row justify-between items-center my-1"
                            v-bind:key="license.size"
                            v-for="license of teamLicenses">
                            <a class="text-lg button border py-1 w-32 rounded-md" href="#"
                              @click.prevent="openCheckout(license.size)">Team of {{license.size}}</a>
                            <span class="text-lg font-bold">{{license.saving}}% saving</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PricingFeature from './PricingFeature.vue'
import { ProductIds, VendorId } from '@/libs/constants'
import { getCookie } from '@/libs/utils'
import { fathomEvent } from '@/libs/analytics'

export default {
  components: {
    PricingFeature
  },
  data () {
    return {
      currency: undefined,
      basicPrice: undefined,
      basicPriceTax: undefined,
      proPrice: undefined,
      proPriceTax: undefined,
      showTeamLicenses: false,
      teamLicenses: [
        { size: 3, saving: 20 },
        { size: 5, saving: 23 },
        { size: 10, saving: 25 },
        { size: 15, saving: 27 },
        { size: 20, saving: 29 },
        { size: 25, saving: 31 }
      ]
    }
  },
  async mounted () {
    const prices = await this.getPrices()

    const findProduct = productId => prices.products.find(x => x.product_id === productId)

    const basic = findProduct(ProductIds.TestingServerlessArchsBasic)
    const pro = findProduct(ProductIds.TestingServerlessArchsPro)

    this.currency = basic.currency
    this.basicPrice = basic.price.net
    this.basicPriceTax = basic.price.tax
    this.proPrice = pro.price.net
    this.proPriceTax = pro.price.tax

    global.Paddle.Setup({ vendor: VendorId })
  },
  methods: {
    async getPrices () {
      try {
        const productIds = Object.values(ProductIds).join(',')
        const url = `https://d16oubqwv0esey.cloudfront.net/api/2.0/prices?product_ids=${productIds}`

        const result = await fetch(url).then(x => x.json())
        if (!result.success) {
          console.error('Paddle returned a failure response')
          return null
        }

        const prices = result.response
        console.log(prices)

        return prices
      } catch (err) {
        console.error('failed to get prices from Paddle', err)
        return null
      }
    },

    buyBasic () {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }
      global.Paddle.Checkout.open({
        product: ProductIds.TestingServerlessArchsBasic,
        passthrough: JSON.stringify(passthrough)
      })

      fathomEvent('click-buy-basic')
    },

    buyPro () {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }
      global.Paddle.Checkout.open({
        product: ProductIds.TestingServerlessArchsPro,
        passthrough: JSON.stringify(passthrough)
      })

      fathomEvent('click-buy-pro')
    },

    openCheckout (size) {
      const passthrough = {
        affiliateId: getCookie('affiliateId')
      }

      switch (size) {
        case 3:
          global.Paddle.Checkout.open({
            passthrough: JSON.stringify(passthrough),
            override: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiVGVzdGluZyBTZXJ2ZXJsZXNzIEFyY2hpdGVjdHVyZXMgKFBybyBwYWNrYWdlKSIsImkiOiJodHRwczpcL1wvcGFkZGxlLnMzLmFtYXpvbmF3cy5jb21cL3VzZXJcLzEyNDg3OFwvMUR5UllEUFRXQ0RQZ3g4RmJiR1VfUmRQaTAwZ3NTNFN6UkJDblRMa3lfU2NyZWVuc2hvdCUyMDIwMjItMTAtMTclMjBhdCUyMDIzLjI5LjA4LnBuZyIsInIiOm51bGwsImNtIjoiU2F2ZSAyMCUiLCJyZSI6MSwicCI6ODA5MTA3LCJjYyI6eyJVU0QiOiIyNDAiLCJHQlAiOiIxOTguNzQiLCJFVVIiOiIyMjQuOTUiLCJBVUQiOiIzNDUuOTUiLCJCUkwiOiIxMjQyLjA0IiwiQ0FEIjoiMzI1LjQxIiwiQ0hGIjoiMjI0LjMzIiwiUExOIjoiMTA1OSIsIlNFSyI6IjI1NDguNyIsIlpBUiI6IjQyNTUuMjIiLCJDWksiOiI1MzQ5LjM2IiwiSFVGIjoiODc3NDAuODQiLCJES0siOiIxNjczLjUxIiwiTlpEIjoiMzc5Ljc4IiwiU0dEIjoiMzIxLjM1IiwiSEtEIjoiMTkxOS44MSIsIklOUiI6IjIwMDMwLjUzIiwiSlBZIjoiMzE3OTgiLCJLUlciOiIzMDEzNTciLCJOT0siOiIyNDM2LjExIiwiVEhCIjoiODAzOS4yMyIsIklMUyI6Ijg0MS43NyJ9LCJxIjowLCJxMiI6MywiZCI6MSwiYSI6W10sInYiOjEyNDg3OCwicyI6IjhiZTJkMGQ1ZDk3MzViNWY1YzA0NGFjYTYwM2M2ZmQ2ZTMxNGFmZDBmN2U5NzUyNzc1Yzg0MTQ2OTNlNjNmODBkZTVjNWI3ZjY0ZTg1ZDZmNjUyOTRkNjBmYmQxMDEyOWE2NTY0YmQzYjA2MTc0ZWJhMDY4NmY0YzBkZWNiMGI4In0='
          })

          break
        case 5:
          global.Paddle.Checkout.open({
            passthrough: JSON.stringify(passthrough),
            override: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiVGVzdGluZyBTZXJ2ZXJsZXNzIEFyY2hpdGVjdHVyZXMgKFBybyBwYWNrYWdlKSIsImkiOiJodHRwczpcL1wvcGFkZGxlLnMzLmFtYXpvbmF3cy5jb21cL3VzZXJcLzEyNDg3OFwvMUR5UllEUFRXQ0RQZ3g4RmJiR1VfUmRQaTAwZ3NTNFN6UkJDblRMa3lfU2NyZWVuc2hvdCUyMDIwMjItMTAtMTclMjBhdCUyMDIzLjI5LjA4LnBuZyIsInIiOm51bGwsImNtIjoiU2F2ZSAyMyUiLCJyZSI6MSwicCI6ODA5MTA3LCJjYyI6eyJVU0QiOiIyMzEiLCJHQlAiOiIxOTEuMjgiLCJFVVIiOiIyMTYuNTEiLCJBVUQiOiIzMzIuOTciLCJCUkwiOiIxMTk1LjQ3IiwiQ0FEIjoiMzEzLjIiLCJDSEYiOiIyMTUuOTIiLCJQTE4iOiIxMDE5LjI5IiwiU0VLIjoiMjQ1My4xMiIsIlpBUiI6IjQwOTUuNjUiLCJDWksiOiI1MTQ4Ljc2IiwiSFVGIjoiODQ0NTAuNTYiLCJES0siOiIxNjEwLjc1IiwiTlpEIjoiMzY1LjU0IiwiU0dEIjoiMzA5LjMiLCJIS0QiOiIxODQ3LjgxIiwiSU5SIjoiMTkyNzkuMzgiLCJKUFkiOiIzMDYwNSIsIktSVyI6IjI5MDA1NiIsIk5PSyI6IjIzNDQuNzUiLCJUSEIiOiI3NzM3Ljc2IiwiSUxTIjoiODEwLjIifSwicSI6MCwicTIiOjUsImQiOjEsImEiOltdLCJ2IjoxMjQ4NzgsInMiOiI0OTdiMzU4YWRlNDdhMTQwZmM2YmJlMWU2OWU3YWNhNWZhNTg3MTE0YTY3NzU3MDlmMjI4ZWJlOWRhNTUzN2Y1YzUwNDY5NjI3MjU4YjUxYmEwNjY1M2U4NmEyZGM1Zjg3ZDZiNDMzZjZhOWQ1N2U5NTZjZGJhZWJmMWQ2NDZiMCJ9'
          })

          break
        case 10:
          global.Paddle.Checkout.open({
            passthrough: JSON.stringify(passthrough),
            override: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiVGVzdGluZyBTZXJ2ZXJsZXNzIEFyY2hpdGVjdHVyZXMgKFBybyBwYWNrYWdlKSIsImkiOiJodHRwczpcL1wvcGFkZGxlLnMzLmFtYXpvbmF3cy5jb21cL3VzZXJcLzEyNDg3OFwvMUR5UllEUFRXQ0RQZ3g4RmJiR1VfUmRQaTAwZ3NTNFN6UkJDblRMa3lfU2NyZWVuc2hvdCUyMDIwMjItMTAtMTclMjBhdCUyMDIzLjI5LjA4LnBuZyIsInIiOm51bGwsImNtIjoiU2F2ZSAyNSUiLCJyZSI6MSwicCI6ODA5MTA3LCJjYyI6eyJVU0QiOiIyMjUiLCJHQlAiOiIxODYuMzEiLCJFVVIiOiIyMTAuODkiLCJBVUQiOiIzMjQuMzIiLCJCUkwiOiIxMTY0LjQxIiwiQ0FEIjoiMzA1LjA3IiwiQ0hGIjoiMjEwLjMxIiwiUExOIjoiOTkyLjgxIiwiU0VLIjoiMjM4OS40MSIsIlpBUiI6IjM5ODkuMjciLCJDWksiOiI1MDE1LjAyIiwiSFVGIjoiODIyNTcuMDQiLCJES0siOiIxNTY4LjkxIiwiTlpEIjoiMzU2LjA0IiwiU0dEIjoiMzAxLjI2IiwiSEtEIjoiMTc5OS44MiIsIklOUiI6IjE4Nzc4LjYyIiwiSlBZIjoiMjk4MTEiLCJLUlciOiIyODI1MjIiLCJOT0siOiIyMjgzLjg1IiwiVEhCIjoiNzUzNi43OCIsIklMUyI6Ijc4OS4xNiJ9LCJxIjowLCJxMiI6MTAsImQiOjEsImEiOltdLCJ2IjoxMjQ4NzgsInMiOiJhZDBmODlhNTFmZGM3YmQ4ODJjYTRkYmFlYzkxMDhkNzE0OWYzYWFhMDIxNjBjZTM1NjdkZGY1ZTRiMDhkNjkwOGQ3OGExZTUyY2VmNmJiMjQ5Y2Y2NzdmNmJjMGJlMGM2NDUzY2E0NDQ3MDZjZjg0YmY1MGE2MGUwZDdkZGQxMiJ9'
          })

          break
        case 15:
          global.Paddle.Checkout.open({
            passthrough: JSON.stringify(passthrough),
            override: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiVGVzdGluZyBTZXJ2ZXJsZXNzIEFyY2hpdGVjdHVyZXMgKFBybyBwYWNrYWdlKSIsImkiOiJodHRwczpcL1wvcGFkZGxlLnMzLmFtYXpvbmF3cy5jb21cL3VzZXJcLzEyNDg3OFwvMUR5UllEUFRXQ0RQZ3g4RmJiR1VfUmRQaTAwZ3NTNFN6UkJDblRMa3lfU2NyZWVuc2hvdCUyMDIwMjItMTAtMTclMjBhdCUyMDIzLjI5LjA4LnBuZyIsInIiOm51bGwsImNtIjoiU2F2ZSAyNyUiLCJyZSI6MSwicCI6ODA5MTA3LCJjYyI6eyJVU0QiOiIyMTkiLCJHQlAiOiIxODEuMzUiLCJFVVIiOiIyMDUuMjciLCJBVUQiOiIzMTUuNjgiLCJCUkwiOiIxMTMzLjM2IiwiQ0FEIjoiMjk2LjkzIiwiQ0hGIjoiMjA0LjciLCJQTE4iOiI5NjYuMzQiLCJTRUsiOiIyMzI1LjY5IiwiWkFSIjoiMzg4Mi44OSIsIkNaSyI6IjQ4ODEuMjkiLCJIVUYiOiI4MDA2My41MiIsIkRLSyI6IjE1MjcuMDciLCJOWkQiOiIzNDYuNTUiLCJTR0QiOiIyOTMuMjMiLCJIS0QiOiIxNzUxLjgyIiwiSU5SIjoiMTgyNzcuODYiLCJKUFkiOiIyOTAxNiIsIktSVyI6IjI3NDk4OCIsIk5PSyI6IjIyMjIuOTUiLCJUSEIiOiI3MzM1LjgiLCJJTFMiOiI3NjguMTEifSwicSI6MCwicTIiOjE1LCJkIjoxLCJhIjpbXSwidiI6MTI0ODc4LCJzIjoiNGI2ZjcwYmZlMzQzZTE2OGFlNTIyZTVkMDA0NjJjMDBiMDVkYmI2OTA2YzBlMDU0MjBjNmNiOGU0MzQxZDE4MzYwZWVhOTNlOTMyNzllOTBkMWU5NDhmNmU2NzVmZTQ4OWZmOWNhZjAzNWYxMzY1YmQzMDY4NjQ1YzVmZTg0OWMifQ=='
          })

          break
        case 20:
          global.Paddle.Checkout.open({
            passthrough: JSON.stringify(passthrough),
            override: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiVGVzdGluZyBTZXJ2ZXJsZXNzIEFyY2hpdGVjdHVyZXMgKFBybyBwYWNrYWdlKSIsImkiOiJodHRwczpcL1wvcGFkZGxlLnMzLmFtYXpvbmF3cy5jb21cL3VzZXJcLzEyNDg3OFwvMUR5UllEUFRXQ0RQZ3g4RmJiR1VfUmRQaTAwZ3NTNFN6UkJDblRMa3lfU2NyZWVuc2hvdCUyMDIwMjItMTAtMTclMjBhdCUyMDIzLjI5LjA4LnBuZyIsInIiOm51bGwsImNtIjoiU2F2ZSAyOSUiLCJyZSI6MSwicCI6ODA5MTA3LCJjYyI6eyJVU0QiOiIyMTMiLCJHQlAiOiIxNzYuMzgiLCJFVVIiOiIxOTkuNjQiLCJBVUQiOiIzMDcuMDMiLCJCUkwiOiIxMTAyLjMxIiwiQ0FEIjoiMjg4LjgiLCJDSEYiOiIxOTkuMDkiLCJQTE4iOiI5MzkuODYiLCJTRUsiOiIyMjYxLjk3IiwiWkFSIjoiMzc3Ni41MSIsIkNaSyI6IjQ3NDcuNTYiLCJIVUYiOiI3Nzg2OS45OSIsIkRLSyI6IjE0ODUuMjQiLCJOWkQiOiIzMzcuMDUiLCJTR0QiOiIyODUuMiIsIkhLRCI6IjE3MDMuODMiLCJJTlIiOiIxNzc3Ny4wOSIsIkpQWSI6IjI4MjIxIiwiS1JXIjoiMjY3NDU0IiwiTk9LIjoiMjE2Mi4wNCIsIlRIQiI6IjcxMzQuODIiLCJJTFMiOiI3NDcuMDcifSwicSI6MCwicTIiOjIwLCJkIjoxLCJhIjpbXSwidiI6MTI0ODc4LCJzIjoiMjRlOGEyOWEyN2M0ZTUyYmM2MzBhZTE5ZmY4YmYxOGQ1YTQ5NWIwYWE2YTI0ZjRiNWVkYjAwMWZhNDVkODE1OTUzN2UzZmY3MjNkYmExMGM1N2RhODhiMWEwYmZlYzI3OTZlNzgwYzk2OWYxYWQ2ZjIzNTE3ZGY0OTI3MGZlMTIifQ=='
          })

          break
        case 25:
          global.Paddle.Checkout.open({
            passthrough: JSON.stringify(passthrough),
            override: 'https://create-checkout.paddle.com/checkout/custom/eyJ0IjoiVGVzdGluZyBTZXJ2ZXJsZXNzIEFyY2hpdGVjdHVyZXMgKFBybyBwYWNrYWdlKSIsImkiOiJodHRwczpcL1wvcGFkZGxlLnMzLmFtYXpvbmF3cy5jb21cL3VzZXJcLzEyNDg3OFwvMUR5UllEUFRXQ0RQZ3g4RmJiR1VfUmRQaTAwZ3NTNFN6UkJDblRMa3lfU2NyZWVuc2hvdCUyMDIwMjItMTAtMTclMjBhdCUyMDIzLjI5LjA4LnBuZyIsInIiOm51bGwsImNtIjoiU2F2ZSAzMSUiLCJyZSI6MSwicCI6ODA5MTA3LCJjYyI6eyJVU0QiOiIyMDciLCJHQlAiOiIxNzEuNDEiLCJFVVIiOiIxOTQuMDIiLCJBVUQiOiIyOTguMzgiLCJCUkwiOiIxMDcxLjI2IiwiQ0FEIjoiMjgwLjY2IiwiQ0hGIjoiMTkzLjQ5IiwiUExOIjoiOTEzLjM5IiwiU0VLIjoiMjE5OC4yNSIsIlpBUiI6IjM2NzAuMTMiLCJDWksiOiI0NjEzLjgyIiwiSFVGIjoiNzU2NzYuNDciLCJES0siOiIxNDQzLjQiLCJOWkQiOiIzMjcuNTYiLCJTR0QiOiIyNzcuMTYiLCJIS0QiOiIxNjU1LjgzIiwiSU5SIjoiMTcyNzYuMzMiLCJKUFkiOiIyNzQyNiIsIktSVyI6IjI1OTkyMCIsIk5PSyI6IjIxMDEuMTQiLCJUSEIiOiI2OTMzLjg0IiwiSUxTIjoiNzI2LjAyIn0sInEiOjAsInEyIjoyNSwiZCI6MSwiYSI6W10sInYiOjEyNDg3OCwicyI6ImQ5MjA4OWU0YTU4ZDAwNzQ4N2RiNGM3NWQzYzYxZTZlOTJhYjhjNDllNGE5N2Y2YTY5ZGJkYzlmN2EwZjU3MzBhZmNlMzE3ZjEwODQ3NWQ5MDJjYmJjZjgzZDcxN2Q4ZjI2N2E4YmZhMDdkMGMwMDRkZjU4MzQ5Y2RiMTQ4ZjQ2In0='
          })

          break
        default:
          alert("Oops, we didn't recognize that team size. If you need help, please email theburningmonk@hey.com with your enquiry.")
          break
      }
    }
  }
}
</script>
